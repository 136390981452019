import ApiService from "@/core/services/api.service";

// action types

export const SET_ERROR = "setError";

export const FETCH_ACURITE_REPORT = "fetchAcuriteReport";
export const STORE_ACURITE_REPORT = "storeAcuriteReport";
export const FETCH_NEST_REPORT = "fetchNestReport";
export const STORE_NEST_REPORT = "storeNestReport";
export const FETCH_ALEXA_REPORT = "fetchAlexaReport";
export const STORE_ALEXA_REPORT = "storeAlexaReport";
export const FETCH_GHOME_REPORT = "fetchGHomeReport";
export const STORE_GHOME_REPORT = "storeGHomeReport";


const state = {
  acurite: [],
  nest:[],
  alexa:[],
  ghome:[]
};

const getters = {
  getAcuriteReports(state) {
    return state.acurite;
  }
};

const actions = {
  [FETCH_ACURITE_REPORT](context) {
    return new Promise(resolve => {
      ApiService.get("/reports/v1","acurite.json")
        .then(({ data }) => {
          context.commit(STORE_ACURITE_REPORT,data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [FETCH_NEST_REPORT](context) {
    return new Promise(resolve => {
      ApiService.get("/reports/v1","nest.json")
        .then(({ data }) => {
          context.commit(STORE_NEST_REPORT,data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [FETCH_GHOME_REPORT](context) {
    return new Promise(resolve => {
      ApiService.get("/reports/v1","ghome.json")
        .then(({ data }) => {
          context.commit(STORE_GHOME_REPORT,data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [FETCH_ALEXA_REPORT](context) {
    return new Promise(resolve => {
      ApiService.get("/reports/v1","alexa.json")
        .then(({ data }) => {
          context.commit(STORE_ALEXA_REPORT,data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
};

const mutations = {
  [STORE_ACURITE_REPORT](state, list) {
    state.acurite = list;
  },
  [STORE_NEST_REPORT](state, list) {
    state.nest = list;
  },
  [STORE_ALEXA_REPORT](state, list) {
    state.alexa = list;
  },
  [STORE_GHOME_REPORT](state, list) {
    state.ghome = list;
  },
  [SET_ERROR](state, errors) {
    console.log("there are errors on device state", errors);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
