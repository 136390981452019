import ApiService from "@/core/services/api.service";

// action types
export const GET_DEVICES = "fetch";
export const SET_ERROR = "setError";
export const PURGE_DEVICES = "purgeDevices";
export const SET_DEVICES = "setDevices";
export const UPDATE_LOCATIONS = "updateDevices";

export const FETCH_RECENT_ACTIVITY = "fetchRecentActivity";
export const STORE_RECENT_ACTIVITY = "storeRecentActivity";

export const FETCH_PUBLIC_DEVICES = "fetchPublicDevices";
export const STORE_PUBLIC_DEVICES = "storePublicDevices";

export const CREATE_VIRTUAL_DEVICE = "createVirtualDevice";
export const CREATE_PHYSICAL_DEVICE = "createPhysicalDevice";
export const CREATE_ACTION = "createAction";
export const CREATE_SCHEDULE = "createSchedule";

export const FETCH_SINGLE_DEVICE = "fetchSingleDevice";
export const DELETE_DEVICE = "deleteDevice";
export const UPDATE_DEVICE = "updateDevice";
export const FETCH_DEVICE_ACTIONS = "fetchDeviceActions";
export const DELETE_DEVICE_ACTIONS = "deleteDeviceActions";
export const FETCH_DEVICE_SCHEDULES = "fetchDeviceSchedules";
export const DELETE_DEVICE_SCHEDULES = "deleteDeviceSchedules";
export const FETCH_DEVICE_LOG = "fetchDeviceLog";
export const FETCH_DEVICE_STATUS = "fetchDeviceStatus";
export const FETCH_DEVICE_PROPS = "fetchDeviceProps";
export const FETCH_DEVICE_PROP = "fetchDeviceProp";
export const FETCH_CHART_DATA = "fetchChartData";
export const FETCH_CHART_DATA_24H = "fetchChartDataDay";
export const FETCH_CHART_DATA_7D = "fetchChartDataWeek";
export const FETCH_CHART_DATA_1M = "fetchChartDataMonth";
export const BROADCAST_ACTION = "broadcastAction";
export const UPDATE_SCREENSAVER = "updateScreensaver";
export const GET_SCREENSAVER = "getScreensaver";
export const SET_FAVOURITE_DEVICE = "setFavouriteDevice";
export const GET_FAVOURITE_DEVICES = "getFavouriteDevices";
export const GET_THERMOSTAT_STATE = "getThermostatState";
export const FORCE_MUTATE_DEVICES = "forceMutateDevices";
export const GET_AVAILABLEDEVICES_BY_SSID = "getAvailableDevicePerSsid";
export const SET_OWNER_TO_DEVICES = "setIdOwnerToDevices";
export const GET_DEVICES_NOTIFICATIONS = "getDeviceNotifications";
export const POST_DISMISS_NOTIFICATIONS = "postDimissNotifications";
export const POST_FINDMY_CREATE = "postFindMyCreate";
export const POST_FINDMY_CREATE_SENSOR = "postFindMyCreateSensor";
export const GET_FINDMY_LOCATION = "getFindMyLocation";
export const STORE_TRACKER_LOCATIONS = "storeTrackerLocations";
export const GET_FINDMY_SOIL_SENSOR = "getFindMySoilSensor";
export const STORE_SOIL_SENSOR_VALUES = "storeSoilSensorValues";
export const GET_BATTERY_DETAILS = "getBatteryDetails";
export const POST_BATTERY_RESET = "postBatteryReset";
export const STORE_BATTERY_INFO = "storeBatteryInfo";
export const STORE_LAST_DEVICE_BATTERY = "storeLastBatteryInfo";
export const GET_BATTERIES = "getBatteries";
export const POST_SAVE_GEOFENCE = "postSaveGeofence";
export const GET_GEOFENCES = "getGeofences";
export const STORE_LAST_DEVICE_GEOFENCES = "storeLastDeviceGeofences";
export const DELETE_GEOFENCE = "deleteGeofence";
export const UPDATE_GEOFENCE = "updateGeofence";

const state = {
  devices: [],
  locations: [],
  soilSensorValues:[],
  activity: [],
  public: [],
  trackerLocations: [],
  battery_info:[],
  last_device_battery:[],
  last_device_geofences:[],
};

const getters = {
  getDevices(state) {
    return state.devices;
  },
  getRecentActivity(state) {
    return state.activity;
  },
  getTrackerLocations(state) {
    return state.trackerLocations;
  },
};

const actions = {
  [GET_DEVICES](context) {
    return new Promise((resolve) => {
      ApiService.get("/devices/v2", "show.json")
        .then(({ data }) => {
          context.commit(SET_DEVICES, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          resolve(response);
        });
    });
  },
  [FORCE_MUTATE_DEVICES](context) {
    context.commit(SET_DEVICES, state.devices);
  },
  [FETCH_RECENT_ACTIVITY](context) {
    return new Promise((resolve) => {
      ApiService.get("/devices/v2", "recent_activity.json")
        .then(({ data }) => {
          data.reverse();
          const map = new Map(data.map((item) => [item.token, item]));
          const distinctItems = Array.from(map.values());
          context.commit(STORE_RECENT_ACTIVITY, distinctItems);
          resolve(data);
        })
        .catch(({ response }) => {
          // empty response.data is considered an error so, lets commit an empty array
          if(response === undefined
            ||response.data === undefined 
            ||response.data.legth === 0) {
            context.commit(STORE_RECENT_ACTIVITY, []);
          } else {
            context.commit(SET_ERROR, response.data.errors);
          }
        });
    });
  },
  [FETCH_PUBLIC_DEVICES](context) {
    return new Promise((resolve) => {
      ApiService.get("/devices/v2", "public.json")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [CREATE_VIRTUAL_DEVICE](context, deviceToCreate) {
    return new Promise((resolve) => {
      ApiService.post("/anet_devices.json", deviceToCreate)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [CREATE_PHYSICAL_DEVICE](context, deviceToCreate) {
    return new Promise((resolve) => {
      ApiService.post("/anet_devices.json", deviceToCreate)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [CREATE_ACTION](context, actionData) {
    return new Promise((resolve) => {
      ApiService.post(
        "/devices/v2/" + actionData.current_token + "/command.json",
        actionData.anet_command
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [CREATE_SCHEDULE](context, scheduleData) {
    return new Promise((resolve) => {
      ApiService.post(
        "/devices/v2/" + scheduleData.current_token + "/schedule.json",
        scheduleData
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [FETCH_SINGLE_DEVICE](context, deviceToken) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.get("/anet_devices", deviceToken.device_token + ".json")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [DELETE_DEVICE](context, deviceToDelete) {
    return new Promise((resolve) => {
      ApiService.delete(
        "/anet_devices/" + deviceToDelete.deviceToRemove + ".json"
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          resolve(response);
        });
    });
  },
  [UPDATE_DEVICE](context, deviceData) {
    return new Promise((resolve, reject) => {
      ApiService.put(
        "/anet_devices/" + deviceData.current_token + ".json",
        deviceData.anet_device
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          const error = response.data.errors;
          context.commit(SET_ERROR, response);
          reject(error); // Reject the promise with the error
        });
    });
  },
  [FETCH_DEVICE_ACTIONS](context, deviceToken) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.get(
        "/devices/v2",
        deviceToken.device_token + "/command/show.json"
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [DELETE_DEVICE_ACTIONS](context, dataObject) {
    return new Promise((resolve) => {
      ApiService.delete(
        "/devices/v2/" +
          dataObject.current_token +
          "/command/" +
          dataObject.current_action_id +
          ".json"
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [DELETE_DEVICE_SCHEDULES](context, scheduleToDelete) {
    return new Promise((resolve) => {
      ApiService.delete(
        "/devices/v2/" +
          scheduleToDelete.current_token +
          "/schedule/" +
          scheduleToDelete.current_schedule_id +
          ".json"
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [FETCH_DEVICE_SCHEDULES](context, deviceToken) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.get(
        "/devices/v2",
        deviceToken.device_token + "/schedule/show.json"
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [FETCH_DEVICE_LOG](context, deviceToken) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.get("/devices/v2", deviceToken.device_token + "/logs.json")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [FETCH_DEVICE_STATUS](context, deviceToken) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.get("/devices/v2", deviceToken.device_token + "/status.json")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [FETCH_DEVICE_PROPS](context, deviceToken) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.get("/devices/v2", deviceToken.device_token + "/props.json")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [FETCH_DEVICE_PROP](context, deviceToken) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.get(
        "/devices/v2",
        deviceToken.device_token + "/prop/" + deviceToken.prop_id + ".json"
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [FETCH_CHART_DATA](context, deviceToken) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.get(
        "/devices/v2",
        deviceToken.device_token +
          "/prop/" +
          deviceToken.prop_id +
          "/chart.json"
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [FETCH_CHART_DATA_24H](context, deviceToken) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.get(
        "/devices/v2",
        deviceToken.device_token +
          "/prop/" +
          deviceToken.prop_id +
          "/chart/24hour.json"
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [FETCH_CHART_DATA_7D](context, deviceToken) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.get(
        "/devices/v2",
        deviceToken.device_token +
          "/prop/" +
          deviceToken.prop_id +
          "/chart/7day.json"
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [FETCH_CHART_DATA_1M](context, deviceToken) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.get(
        "/devices/v2",
        deviceToken.device_token +
          "/prop/" +
          deviceToken.prop_id +
          "/chart/monthly.json"
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [BROADCAST_ACTION](context, payload) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.post("/devices/v2/command/send.json", {
        token: payload.token,
        content: payload.command_data,
        secret_token: payload.secret,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [UPDATE_SCREENSAVER](context, payload) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.upload(
        "/devices/v2/" + payload.get("token") + "/screensaver.json",
        payload
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_SCREENSAVER](context, payload) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.get("/devices/v2/" + payload.token + "/screensaver.json")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [SET_FAVOURITE_DEVICE](context, payload) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.post("/devices/v2/" + payload.token + "/fav.json", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_FAVOURITE_DEVICES](context) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.get("/devices/v2/favs.json")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_AVAILABLEDEVICES_BY_SSID](context, queryString) {
    return new Promise((resolve) => {
      ApiService.get("/devices/ssid", "availables.json" + queryString)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [SET_OWNER_TO_DEVICES](context, payload) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.post("/devices/ssid/owner.json", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_DEVICES_NOTIFICATIONS](context) {
    return new Promise((resolve) => {
      ApiService.get("/devices", "notifications.json")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [POST_DISMISS_NOTIFICATIONS](context) {
    return new Promise((resolve) => {
      ApiService.post("/devices/notifications/dismiss.json", {})
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [POST_FINDMY_CREATE](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("/devices/v2/findmy/create.json", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_FINDMY_LOCATION](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        "/devices/v2/findmy",
        `${payload.device_token}/location.json?start_date=${payload.start_date}&end_date=${payload.end_date}`
      )
        .then(({ data }) => {
          context.commit(STORE_TRACKER_LOCATIONS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response);
        });
    });
  },
  [POST_FINDMY_CREATE_SENSOR](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("/devices/v2/findmy/sensor.json", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_FINDMY_SOIL_SENSOR](context, payload) {
    return new Promise((resolve) => {
      ApiService.get(
        "/devices/v2/findmy/",
        payload.device_token + "/soil.json"
      )
        .then(({ data }) => {
          context.commit(STORE_SOIL_SENSOR_VALUES, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [POST_BATTERY_RESET](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("/devices/v2/"+payload.token+"/battery/reset.json", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_BATTERY_DETAILS](context, payload) {
    return new Promise((resolve) => {
      ApiService.get(
        "/devices/v2",
        payload.device_token + "/battery.json"
      )
        .then(({ data }) => {
          context.commit(STORE_LAST_DEVICE_BATTERY, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_BATTERIES](context) {
    return new Promise((resolve) => {
      ApiService.get(
        "/batteries/v2","show.json"
      )
        .then(({ data }) => {
          context.commit(STORE_BATTERY_INFO, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [POST_SAVE_GEOFENCE](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("/geofences.json", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_GEOFENCES](context,payload) {
    return new Promise((resolve) => {
      ApiService.get(
        "/geofences/v1",payload.device_token
      )
        .then(({ data }) => {
          context.commit(STORE_BATTERY_INFO, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [DELETE_GEOFENCE](context, payload) {
    return new Promise((resolve) => {
      ApiService.delete(
        `/geofences/${payload.geofence_id}.json`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          resolve(response);
        });
    });
  },
  [UPDATE_GEOFENCE](context, payload) {
    return new Promise((resolve) => {
      ApiService.patch(`/geofences/${payload.geofence_id}.json`, {
      token: payload.token,
      notify_enter: payload.notify_enter,
      notify_exit: payload.notify_exit,
    }).then(({ data }) => {
      resolve(data);
    }).catch(({ response }) => {
      resolve(response);
    });
  });
}
};

const mutations = {
  [PURGE_DEVICES](state) {
    state.devices = [];
    state.locations = [];
    state.activity = [];
  },
  [UPDATE_LOCATIONS](state, mylocation) {
    state.locations = mylocation;
  },
  [SET_DEVICES](state, devices) {
    state.devices = devices;
  },
  [STORE_RECENT_ACTIVITY](state, devices) {
    state.activity = devices;
  },
  [STORE_PUBLIC_DEVICES](state, devices) {
    state.public = devices;
  },
  [STORE_TRACKER_LOCATIONS](state, locations) {
    state.trackerLocations = locations;
  },
  [STORE_SOIL_SENSOR_VALUES](state, locations) {
    state.soilSensorValues = locations;
  },
  [STORE_BATTERY_INFO](state,batteries){
    state.battery_info = batteries;
  },
  [STORE_LAST_DEVICE_BATTERY](state,batteries){
    state.last_device_battery = batteries;
  },
  [STORE_LAST_DEVICE_GEOFENCES](state,geofences){
    state.last_device_geofences = geofences;
  },
  [SET_ERROR](state, errors) {
    console.log("there are errors on device state", errors);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
