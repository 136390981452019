<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
// import io  from "socket.io-client";

export default {
  name: "SuitchNetwork",
  data(){
    return {
      zocket:null,
      zocketId:'',
      unsubscribe: null,
    }
  },
  beforeDestroy() {
    // Unsubscribe from the store when the component is destroyed
    if (this.unsubscribe) {
      console.log("unsubscribing APP.vue");
      this.unsubscribe();console.log("kill_subscribe");
    }
  },
  mounted() {
    if (localStorage.getItem("user")!=undefined
    && localStorage.getItem("user")!=null
    && this.zocket==null) {
      this.globalRealTime();
    }
    this.unsubscribe=this.$store.subscribe((mutation, state) => {
      state;
      if (mutation.type === "setUser" && this.zocket==null) {
        // a new user has correctly logged in, lets setup real time data
        //this.globalRealTime();
      } else if (mutation.type === "logOut" && this.zocket!=null) {
        // a new user has correctly logged in, lets setup real time data
        if(this.zocket != null && this.zocket != undefined){
          // verify if the zocket is connected
          console.log("zocket is connected");
          if(this.zocket.connected){
            console.log("zocket is connected_2");
            this.zocket.disconnect();
            this.zocket=null;
          }
        }
      }
    });
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
  methods:{
    setSocketId(currentId) {
      this.zocketId = currentId
    },
    sendSocketIdToServer(socketRoute) {
    console.log({
      'action': socketRoute,
      'socketId': this.zocketId,
      'token': JSON.parse(localStorage.getItem('user')).user_id
    });
    this.zocket?.send(JSON.stringify({
      'action': socketRoute,
      'socketId': this.zocketId,
      'token': JSON.parse(localStorage.getItem('user')).user_id
    }))
  },
    globalRealTime() {
      // this.zocket = io("https://f.suitch.network");
      // // client-side
      // this.zocket.on("connect", () => {
      //   // code
      // });
      // this.zocket.on(JSON.parse(localStorage.getItem('user')).user_id,(data)=>{
      //   // console.log("REALTIME_PROPAGATE: ", data);

      //   if(data.type !== 'props') {
      //       this.$store.dispatch("REALTIME_PROPAGATE",data);
      //   }
        
        
      // })
      // this.zocket.io.on("reconnect_attempt", () => {
      //   // console.log("reconnect_attempt");
      // });

      // this.zocket.io.on("reconnect", () => {
      //   // console.log("reconnect");
      // });
      // this.zocket.on("disconnect", () => {
      //   // console.log(this.zocket.id); // undefined
      // });

    this.zocket = new WebSocket("wss://u5danmbjxb.execute-api.us-east-1.amazonaws.com/dev")
    this.zocket.onopen = () => {
      this.sendSocketIdToServer('open')
    }
    this.zocket.onclose = () => {
      console.log('closed zocket');
    }
    this.zocket.onmessage = (messageEvent) => {
      const data = JSON.parse(messageEvent.data)
      if (data['socketId']) {
        console.log('socketId', data['socketId']);
        this.setSocketId(data['socketId'])
      }
      if (data['message']){
        console.log("message",JSON.parse(data['message']));
          if(data['message'].type !== 'prop') {
            this.$store.dispatch("REALTIME_PROPAGATE",data);
        }
      }
    }
    }
  }
};
</script>
