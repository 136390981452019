import "popper.js";
import "tooltip.js";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@/core/plugins/treeselect";
import "@mdi/font/css/materialdesignicons.css";
import 'vue-dnd-zone/vue-dnd-zone.css'
import 'jquery-ui/ui/widgets/draggable'
import 'jquery-ui/ui/widgets/droppable'

import * as VueGoogleMaps from 'vue2-google-maps/src/main'

import ApiService from "@/core/services/api.service";
import App from "./App.vue";
import ClipboardJS from "clipboard";
import PerfectScrollbar from "perfect-scrollbar";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import Vue from "vue";
import i18n from "@/core/plugins/vue-i18n";
import moment from 'moment'
import router from "./router";
import store from "@/core/services/store";
import vuetify from "@/core/plugins/vuetify";
import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

// import "@/core/plugins/draw2d/Application";
















// import "@/core/plugins/draw2d/Toolbar";
// import "@/core/plugins/draw2d/View";
// import "@/core/plugins/draw2d/TableShape";
















// import MockService from "@/core/mock/mock.service";









Vue.config.productionTip = false;

// Global 3rd party plugins



window.PerfectScrollbar = PerfectScrollbar;

window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins



Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY HH:mm')
  }
})
Vue.filter('capitalize', function(value) {
  if (value) {
    const lower = String(value).toLowerCase();
    return String(value).charAt(0).toUpperCase() + lower.slice(1);
  }
});




// AIzaSyD04N7JMcPj7RfcKEGSR_ZVxwYH9NM2afI
Vue.use(VueGoogleMaps, {
  installComponents: true,
  load: {
    key: 'AIzaSyBZaFXyd4_8iMAH-VMxpLDMcCm0GC8n0Lo',
    libraries: 'places,geometry',
  }
});

Vue.use(VueFlatPickr);

// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
