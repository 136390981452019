// USA
export const locale = {
  GLOBAL: {
    Cancel: "Cancel",
    OK: "OK"
  },
  LOGIN: {
    WELCOME: "Welcome",
    TO: "to", 
    CREATE: "Create",
    AN: "an",
    ACCOUNT: "account",
    NEW: "New", 
    HERE: "Here?",
    EMAIL: "Email",
    PASSWORD: "Password",
    FORGOTPASSWORD: "Forgot Password?",
    SIGNIN: "Sign in"

  },
  NOTIFICATIONS:{
    VALUE:"Value:"
  },
  DropdownCreate: {
    REGISTERNEWDEVICE: "Register new device",
    RegisterProduct: "Register product",
    AddFromNetwork: "Add from network",
    CreateVirtualDevice: "Create virtual device"
  },
  Header: {
    Search: "Search ..."
  },
  Dashboard: {
    Favouritedevices: "Favourite devices",
    RecentDeviceActivity: "Recent device activity",
    Locationofdevices: "Location of devices",
    Virtualassistantinteractionsthisweek: "Virtual assistant interactions this week",
    MoreThanXCommands: "More than ",
    CommandsThisWeek: " commands this week",
    Addvirtualassistant: "Add virtual assistant",
    Suitch: "Suitch",
    Window1: "Ventana 1",
    Siri: "Siri",
    Window2: "Ventana 2",
    GoogleHome: "Google Home",
    Alexa: "Alexa",
    Nest: "Nest",
    Acurite: "Acurite",
    Virtualassistant: "Virtual assistant",
    provider: "provider",
    commandsreceived: "commands received",
    action: "action",
    received: "received",
    Otherspublicdevices: "Others public devices"
  },
  chartsAdvance: {
    EnergyUsedInkw: "Energy used in kw/h",
    Deviceswithmoreenergyused: "Devices with more energy used",
    Months12: "12 Months",
    Weeks4: "4 Weeks",
    Days7: "7 Days",
    hours24: "24 hours",
    Alertmsg: "In this page you will be able to chart several devices at once"
  },
  chainReactions: {
    Inthispageyoucancreatereactions: "In this page you can create reactions to events or incoming data",
    Chainreactions: "Chain reactions",
    AddDeviceChainReaction: "+ device chain reaction",
    Addcondition: "Add condition",
    AddDevice: "Add Device",
    AddDeviceToNotify: "+ device to notify",
    Savechanges: "Save changes",
    Eventorigin: "Event origin",
    Nooriginselected: "No origin selected",
    ThisIsTheOriginFromwillbelistened: "This is the origin from which events will be listened",
    Selectableprops: "Selectable props",
    Nopropselected: "No prop selected",
    ThisIsPropWillTriggerChainEvent: "This is the prop that will trigger the chain of events",
    When: "When",
    Nooperatorselected: "No operator selected",
    ConditionWillBeTested: "This is the condition that will be tested when new values are received",
    MakeSureWritePropper: "Make sure you write a propper value to compare to",
    Destinationdevice: "Destination device",
    Nodestinationselected: "No destination selected",
    ThisDestinationDeviceToNotify: "This is the destination device to notify"
  },
  networkDeviceWizard: {
    step1: {
      title: "Setup Your Current Network",
      ssidNetwork: "SSID Network",
      ssidNetworkRequired: "The SSID Network is required"
    },
    step2: {
      title: "Select your devices",
      ssid: "SSID",
      device: "Device",
      object: "Object",
      nodata: "There aren't available devices"
    }
  },
  Reports:{
    acurite:{
      title: "Acurite report/inbox",
      description: "In this page you will be able to see the reports and inbox messages from your Acurite device",
    },
    nest:{
      title: "Nest notification history",
      description: "In this page you will be able to see the reports and inbox messages from your Nest account",
    },
    googleHome:{
      title: "Google home history",
      description: "In this page you will be able to see the history of google home requests with your devices",
    },
    alexa:{
      title: "Alexa history",
      description: "Alexa history request with Suitch system",
    }
  }
};
